import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../../layout/Layout';
import Seo from '../../components/Seo';
import Nav from '../../components/Nav/Nav';
import { Helmet } from 'react-helmet';

const Privacy = ({ data }) => {
const pageData = data?.allContentfulPrivacyPage?.edges[0]?.node;


	useEffect(()=>{
		const script = document.createElement("script");
        script.src = 'https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js'; // whatever url you want here
        script.charset = "utf-8";
				script.id="otprivacy-notice-script";
				script.settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"
        script.async = true;
        script.onload = function () {
					window.OneTrust?.NoticeApi?.Initialized?.then(function() {
						window.OneTrust.NoticeApi.LoadNotices(["https://privacyportal-cdn.onetrust.com/73dca12b-5ba4-4937-9072-b5ffa15d1ba7/privacy-notices/cf03b220-3512-4bd1-9453-07b7a003b08e.json"]);
					});
        };
        document.head.appendChild(script);
	}, [])

	if(!data){
		return <></>
	} 

	return (
		<Layout>
			<Seo
				lang="fr"
				title={pageData?.tItle || 'Publicis et Nous'}
				description={pageData?.description || 'Publicis et Nous'}
			/>
			<div id="main-wrapper" className="row">
				<Nav />
				<section id="container" className="privacy col-lg-9 col-md-12">
					<div className="page-data-privacy inner-wrap">
						<div className="page-content inner-page">
							<div className="content-wrap" style={{ marginTop: '30px'}}>
							<div id="otnotice-cf03b220-3512-4bd1-9453-07b7a003b08e" className="otnotice"></div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export const query = graphql`
	{
	allContentfulPrivacyPage{
		edges {
			node{
				tItle
				description
			}
	}
	}
	}
`

export default Privacy;